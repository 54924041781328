import { gsap } from "gsap";
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"

 
const ScrollingTextBanner = ({title, caption, image,children,className,textClassName, website}) => {
  useEffect(() => {
    gsap.fromTo('.banner--title, .banner--image', {opacity:0}, { opacity:1, duration: .75, delay:0.5, ease: "power2.inOut"});
  },[]); 
  return ( 
    <>
   
  <div className={"scrolling-text--banner flex pl40 pr40 h-100vh  " + className}> 
  
    <div className="max-1400 mla mra w-100 flex pb40 mb40 m-pb60 ">
      
      <div className="pb40  m-100 m-p0 flex flex-wrap flex-column"> 
        <div className="w-100 flex-grow">
          <div className={"m0 mb40 banner--title align-self--top sticky--top m-t70 w-100 " + textClassName}>
              <h1 className='h1 m0 pt20 m-mt20 m-flex'>
                <div className="mr20">
                {title} 
                </div>
                <div className="max-100 w-100 mla m-show">
                  <div className="ratio-2-1 bg-grey sticky--top mb40 banner--image m-t70"> 
                    <GatsbyImage className='bg-image' image={image}  alt={title} />
                  </div>
                </div>

              </h1>
              {children} 
            </div>
        </div>
        <div className="text-bottom max-700 align-self--start m-align-self--unset h3 ">
          {caption}
        </div>
      </div>
      <div className="max-180 w-100 mla mt20 m-hide">
        <div className="ratio-2-1 bg-grey sticky--top mb40 banner--image m-t70"> 
          <GatsbyImage className='bg-image' image={image}  alt={title} />
        </div>
      </div>
    </div>
  </div>  
  </>
  )
} 

export default ScrollingTextBanner
